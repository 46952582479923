<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="issues" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading">
                <div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div>
                <router-link :to="`/${project.group.path}/${project.path}/issues`" class="no-link fs-16 text-muted">Issues</router-link> &bull; #{{ issue.number }}
              </h5>
            </div>
            <div class="page-title-right">
              <b-button-group v-if="issue.created_by.id === $auth.profile.id || $auth.profile.site_admin" class="mr-sm-2">
                <b-button variant="primary" class="btn-rounded" @click="editComment('edit', issue.description.id)">Edit</b-button>
                <b-dropdown v-if="issue.comments.length === 0 || $auth.profile.site_admin" variant="primary" toggle-class="btn-rounded" menu-class="dropdown-card w-dropdown-card-small mt-1" right>
                  <template v-slot:button-content><i class="fal fa-sm fa-chevron-down"></i></template>
                  <div class="card">
                    <header class="card-header d-flex justify-content-between">
                      <i class="fal fa-pencil text-primary" aria-hidden="true"></i>
                      <span class="heading-font-family flex-1 text-center fw-400">Issue</span>
                    </header>
                    <div class="card-body list-unstyled dropdown-list">
                      <button type="button" class="dropdown-item" @click="editComment('edit', issue.description.id)">Edit</button>
                      <div class="dropdown-divider"></div>
                      <button type="button" class="dropdown-item text-danger" v-b-modal.deleteissuemodal><i class="fal fa-times mr-2"></i>Delete</button>
                    </div>
                  </div>
                </b-dropdown>
              </b-button-group>
              <router-link :to="`/${project.group.path}/${project.path}/issues/new`" class="btn btn-default btn-rounded d-none d-sm-inline-flex" role="button"><i class="fal fa-pencil mr-2"></i>New Issue</router-link>
            </div>
          </div>

          <div class="pb-3 mb-3 text-muted">
            <h5 class="page-title-heading mt-3 mb-2">{{ issue.title }}</h5>
            <span class="d-none d-md-inline-block badge text-capitalize text-white py-2 px-3 mr-2" :class="issue.state === 'open' ? 'badge-success' : 'badge-danger'">{{ issue.state }}</span>
            Opened {{ $datefns.fromNow(issue.created_at) }} by {{ issue.created_by.username }} <span class="d-none d-md-inline-block"><span class="mx-1">&bull;</span> {{ issue.comments.length }} comment<span v-if="issue.comments.length !== 1">s</span></span>
          </div>

          <div class="row">
            <div class="col-md-9">
              <div class="widget-list issuelist">

                <div class="clearfix">
                  <div class="float-left d-none d-md-block">
                    <img :src="issue.description.created_by.avatar" class="avatar-md rounded-circle">
                  </div>
                  <div class="float-right issuedetails-comment-body">
                    <div class="widget-holder mb-0">
                      <div class="widget-bg">
                        <div class="clearfix issuedetails-comment-title">
                          <div class="float-left text-dark"><img :src="issue.description.created_by.avatar" class="avatar-sm rounded-circle d-md-none mr-2">{{ issue.description.created_by.username }} <span class="text-muted">&bull; {{ $datefns.fromNow(issue.created_at) }}</span></div>
                          <div class="float-right d-none d-md-inline-block">
                            <span v-if="issue.created_by.site_admin" class="badge border text-muted small"><span class="full">{{ $config.name }}</span></span>
                            <b-dropdown v-if="issue.created_by.id === $auth.profile.id || $auth.profile.site_admin" variant="link" toggle-class="py-0 px-3" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                              <template v-slot:button-content><i class="fal fa-lg fa-ellipsis-v"></i></template>
                              <div class="card">
                                <header class="card-header d-flex justify-content-between">
                                  <i class="fal fa-pencil text-primary" aria-hidden="true"></i>
                                  <span class="heading-font-family flex-1 text-center fw-400">Issue</span>
                                </header>
                                <div class="card-body list-unstyled dropdown-list">
                                  <button type="button" class="dropdown-item" @click="editComment('edit', issue.description.id)">Edit</button>
                                  <div v-if="issue.comments.length === 0 || $auth.profile.site_admin" class="dropdown-divider"></div>
                                  <button v-if="issue.comments.length === 0 || $auth.profile.site_admin" type="button" class="dropdown-item text-danger" v-b-modal.deleteissuemodal><i class="fal fa-times mr-2"></i>Delete</button>
                                </div>
                              </div>
                            </b-dropdown>
                            <span v-else class="d-inline-block mr-3"></span>
                          </div>
                        </div>
                        <div class="widget-body markdown" v-html="markdown.render(issue.description.body)"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="issuedetails-connect"></div>

                <div v-for="e in groupEvents" :key="`${e.event}-${e.created_by.id}-${e.created_at}`">
                  <div v-if="e.event === 'comment' && comment(e.id)">
                    <div class="clearfix">
                      <div class="float-left d-none d-md-block">
                        <img :src="comment(e.id).created_by.avatar" class="avatar-md rounded-circle">
                      </div>
                      <div class="float-right issuedetails-comment-body">
                        <div class="widget-holder mb-0">
                          <div class="widget-bg">
                            <div class="clearfix issuedetails-comment-title">
                              <div class="float-left text-dark"><img :src="comment(e.id).created_by.avatar" class="avatar-sm rounded-circle d-md-none mr-2">{{ comment(e.id).created_by.username }} <span class="text-muted">&bull; {{ $datefns.fromNow(comment(e.id).created_at) }}</span></div>
                              <div class="float-right d-none d-md-inline-block">
                                <span v-if="comment(e.id).created_by.site_admin" class="badge border text-muted small"><span class="full">{{ $config.name }}</span></span>
                                <b-dropdown v-if="e.created_by.id === $auth.profile.id || $auth.profile.site_admin" variant="link" toggle-class="py-0 px-3" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                                  <template v-slot:button-content><i class="fal fa-lg fa-ellipsis-v"></i></template>
                                  <div class="card">
                                    <header class="card-header d-flex justify-content-between">
                                      <i class="fal fa-pencil text-primary" aria-hidden="true"></i>
                                      <span class="heading-font-family flex-1 text-center fw-400">Comment</span>
                                    </header>
                                    <div class="card-body list-unstyled dropdown-list">
                                      <button type="button" class="dropdown-item" @click="editComment('edit', e.id)">Edit</button>
                                      <div class="dropdown-divider"></div>
                                      <button type="button" class="dropdown-item text-danger" @click="editComment('delete', e.id)"><i class="fal fa-times mr-2"></i>Delete</button>
                                    </div>
                                  </div>
                                </b-dropdown>
                                <span v-else class="d-inline-block mr-3"></span>
                              </div>
                            </div>
                            <div class="widget-body markdown" v-html="markdown.render(comment(e.id).body)"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="issuedetails-connect"></div>
                  </div>
                  <div v-else-if="e.event === 'label' && label(e.id)">
                    <div class="clearfix issuedetails-action">
                      <div class="float-left issuedetails-action-icon"><i class="fal fa-tag fa-fw text-muted rounded-circle"></i></div>
                      <div class="float-right issuedetails-action-description">
                        {{ e.created_by.username }} <span class="text-muted">added <label-badge v-if="!e.ids" :color="label(e.id).color" :text="label(e.id).text_color" :label="label(e.id).name" :tooltip="label(e.id).description" class="mr-1 mb-1" /><label-badge v-else v-for="id in e.ids" :key="id" :color="label(id).color" :text="label(id).text_color" :label="label(id).name" :tooltip="label(id).description" class="mr-1 mb-1" />label<span v-if="e.ids && e.ids.length > 1">s</span> &bull; {{ $datefns.fromNow(e.created_at) }}</span>
                      </div>
                    </div>
                    <div class="issuedetails-connect"></div>
                  </div>
                  <div v-else-if="e.event === 'unlabel' && label(e.id)">
                    <div class="clearfix issuedetails-action">
                      <div class="float-left issuedetails-action-icon"><i class="fal fa-tag fa-fw text-muted rounded-circle"></i></div>
                      <div class="float-right issuedetails-action-description">
                        {{ e.created_by.username }} <span class="text-muted">removed <label-badge v-if="!e.ids" :color="label(e.id).color" :text="label(e.id).text_color" :label="label(e.id).name" :tooltip="label(e.id).description" class="mr-1 mb-1" /><label-badge v-else v-for="id in e.ids" :key="id" :color="label(id).color" :text="label(id).text_color" :label="label(id).name" :tooltip="label(id).description" class="mr-1 mb-1" />label<span v-if="e.ids && e.ids.length > 1">s</span> &bull; {{ $datefns.fromNow(e.created_at) }}</span>
                      </div>
                    </div>
                    <div class="issuedetails-connect"></div>
                  </div>
                  <div v-else-if="e.event === 'renamed'">
                    <div class="clearfix issuedetails-action">
                      <div class="float-left issuedetails-action-icon"><i class="fal fa-pencil fa-fw text-muted rounded-circle"></i></div>
                      <div class="float-right issuedetails-action-description">
                        {{ e.created_by.username }} <span class="text-muted">changed the title from <span class="text-dark">{{ e.from }}</span> to <span class="text-dark">{{ e.to }}</span> &bull; {{ $datefns.fromNow(e.created_at) }}</span>
                      </div>
                    </div>
                    <div class="issuedetails-connect"></div>
                  </div>
                  <div v-else-if="e.event === 'reopened'">
                    <div class="clearfix issuedetails-action">
                      <div class="float-left issuedetails-action-icon"><i class="fal fa-circle fa-fw bg-success rounded-circle"></i></div>
                      <div class="float-right issuedetails-action-description">
                        {{ e.created_by.username }} <span class="text-muted">reopened issue &bull; {{ $datefns.fromNow(e.created_at) }}</span>
                      </div>
                    </div>
                    <div class="issuedetails-connect"></div>
                  </div>
                  <div v-else-if="e.event === 'closed'">
                    <div class="clearfix issuedetails-action">
                      <div class="float-left issuedetails-action-icon"><i class="fal fa-ban fa-fw bg-danger rounded-circle"></i></div>
                      <div class="float-right issuedetails-action-description">
                        {{ e.created_by.username }} <span class="text-muted">closed issue &bull; {{ $datefns.fromNow(e.created_at) }}</span>
                      </div>
                    </div>
                    <div class="issuedetails-connect"></div>
                    <hr class="issuedetails-separator mx-0 mt-0 mb-4 pb-2" style="border-top-width: 4px; border-color: #e0e0e0;">
                  </div>
                </div>

                <hr v-if="groupEvents[groupEvents.length - 1].event !== 'closed'" class="issuedetails-separator m-0 pb-2">
                <div class="clearfix mt-4">
                  <div class="float-left d-none d-md-block">
                    <img :src="$auth.profile.avatar" class="avatar-md rounded-circle">
                  </div>
                  <div class="float-right issuedetails-comment-body">
                    <div class="widget-holder mb-0">
                      <div class="widget-bg">
                        <div class="widget-body">

                          <div class="form-group rounded comment-box">
                            <b-tabs v-model="postCommentTab" content-class="p-0 mt-3" no-nav-style no-fade>
                              <template v-slot:tabs-end>
                                <div class="toolbar ml-xl-auto">
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').header()"><i class="fal fa-fw fa-heading" v-b-tooltip.hover title="Add header text"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').bold()"><i class="fal fa-fw fa-bold" v-b-tooltip.hover title="Add bold text"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').italic()"><i class="fal fa-fw fa-italic" v-b-tooltip.hover title="Add italic text"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').quote()"><i class="fal fa-fw fa-quote-right" v-b-tooltip.hover title="Insert a quote"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').code()"><i class="fal fa-fw fa-code" v-b-tooltip.hover title="Insert code"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').link()"><i class="fal fa-fw fa-link" v-b-tooltip.hover title="Add a link"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').ul()"><i class="fal fa-fw fa-list-ul" v-b-tooltip.hover title="Add a bullet list"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').ol()"><i class="fal fa-fw fa-list-ol" v-b-tooltip.hover title="Add a numbered list"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').task()"><i class="fal fa-fw fa-tasks" v-b-tooltip.hover title="Add a task list"></i></button>
                                  <button class="btn btn-link" type="button" @click="markdown.insert('form-body').table()"><i class="fal fa-fw fa-table" v-b-tooltip.hover title="Add a table"></i></button>
                                </div>
                              </template>
                              <b-tab title="Write" active>
                                <Textarea ref="form-body" v-model="form.body" class="border-none text-monospace" placeholder="Write a comment" />
                                <hr class="my-2">
                                <div class="row">
                                  <div class="col-sm text-muted small py-1">
                                    Styling with Markdown is supported
                                  </div>
                                  <div class="col-sm text-left text-sm-right">
                                    <label class="btn btn-link py-1 px-0 m-0"><i class="fal fa-paperclip text-muted mr-1"></i>Attach a file<upload :group="project.group.path" :project="project.path" type="uploads" el="form-body" @callback="insertLink" /></label>
                                  </div>
                                </div>
                              </b-tab>
                              <b-tab title="Preview" class="preview markdown" @click="updatePreview('form', form.body)" v-html="preview.form"></b-tab>
                            </b-tabs>
                          </div>
                          <button @click="postComment()" class="btn btn-primary btn-rounded mt-3 mr-2" type="button">Comment</button>
                          <button v-if="(issue.created_by.id === $auth.profile.id || $auth.profile.site_admin) && issue.state !== 'closed'" @click="postComment('closed')" class="btn btn-default btn-rounded mt-3" type="button">Close</button>
                          <button v-if="(issue.created_by.id === $auth.profile.id || $auth.profile.site_admin) && issue.state !== 'open'" @click="postComment('open')" class="btn btn-default btn-rounded mt-3" type="button">Reopen</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-3 pr-md-0 px-4 pt-4 pt-md-0 issuecontrols">

              <div class="clearfix mb-2"><div class="float-left"><h6 class="fs-14 fw-500 m-0">Assignee</h6></div><div class="float-right" v-if="$auth.profile.site_admin">
                <b-dropdown toggle-class="p-0" variant="link" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                  <template v-slot:button-content><i class="fal fa-lg fa-plus text-muted"></i></template>
                  <div class="card">
                    <header class="card-header d-flex justify-content-between">
                      <i class="fal fa-users text-primary" aria-hidden="true"></i>
                      <span class="heading-font-family flex-1 text-center fw-400">Members</span>
                    </header>
                    <div class="card-body list-unstyled dropdown-list">
                      <button v-for="m in members" :key="m.id" type="button" class="dropdown-item" @click="toggleAssignee(m)"><i class="fal fa-fw mr-2 text-primary" :class="issue.assigned_to && issue.assigned_to.id === m.id ? 'fa-check-square' : 'fa-square'"></i>{{ m.name }}</button>
                    </div>
                  </div>
                </b-dropdown>
              </div></div>
              <div v-if="!issue.assigned_to" class="text-muted">None</div>
              <div v-else>{{ issue.assigned_to.name }}</div>

              <hr class="issuedetails-separator my-3 pb-1">

              <div class="clearfix mb-2"><div class="float-left"><h6 class="fs-14 fw-500 m-0">Labels</h6></div><div class="float-right" v-if="$auth.profile.site_admin">
                <b-dropdown toggle-class="p-0" variant="link" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                  <template v-slot:button-content><i class="fal fa-lg fa-plus text-muted"></i></template>
                  <div class="card">
                    <header class="card-header d-flex justify-content-between">
                      <i class="fal fa-tag text-primary" aria-hidden="true"></i>
                      <span class="heading-font-family flex-1 text-center fw-400">Labels</span>
                    </header>
                    <div class="card-body list-unstyled dropdown-list">
                      <button v-for="l in labels" :key="l.id" type="button" class="dropdown-item" @click="toggleLabel(l)"><i class="fal fa-fw mr-2" :class="labelActive(l) ? 'fa-check-square' : 'fa-square'" :style="`color: #${l.color}`"></i>{{ l.name }}</button>
                    </div>
                  </div>
                </b-dropdown>
              </div></div>
              <div v-if="issue.labels.length === 0" class="text-muted">None</div>
              <div v-else>
                <label-badge v-for="l in issue.labels" :key="l.id" :color="l.color" :text="l.text_color" :label="l.name" :tooltip="l.description" class="mr-1 mb-1" />
              </div>

              <hr class="issuedetails-separator my-3 pb-1">

              <div class="clearfix"><div class="float-left"><h6 class="fs-14 fw-500 m-0">Notifications</h6></div><div class="float-right">
                <button type="button" class="btn btn-link p-0" v-if="subscribe()" @click="subscribe(false)"><i class="fal fa-lg fa-toggle-on text-primary"></i></button>
                <button type="button" class="btn btn-link p-0" v-else @click="subscribe(true)"><i class="fal fa-lg fa-toggle-off text-muted"></i></button>
              </div></div>

              <hr class="issuedetails-separator my-3 pb-1">

              <h6 class="fs-14 fw-500 mt-0 mb-2">Participants</h6>
              <p><span v-for="p in participants" :key="p.id"><img :src="p.avatar" class="avatar-sm rounded-circle mr-1"></span></p>

            </div>
          </div>

          <b-modal id="editcommentmodal" title="Edit" size="lg" centered>
            <div v-if="edit.id === issue.description.id">
              <div class="form-group">
                <label class="col-form-label">Title</label>
                <input type="text" class="form-control" v-model="edit.title" maxlength="64">
              </div>
              <label class="col-form-label">Description</label>
            </div>
            <div class="issuedetails-comment-body w-100">
              <div class="form-group rounded comment-box">
                <b-tabs v-model="editCommentTab" content-class="p-0 mt-3" no-nav-style no-fade>
                  <template v-slot:tabs-end>
                    <div class="toolbar ml-xl-auto">
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').header()"><i class="fal fa-fw fa-heading" v-b-tooltip.hover title="Add header text"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').bold()"><i class="fal fa-fw fa-bold" v-b-tooltip.hover title="Add bold text"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').italic()"><i class="fal fa-fw fa-italic" v-b-tooltip.hover title="Add italic text"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').quote()"><i class="fal fa-fw fa-quote-right" v-b-tooltip.hover title="Insert a quote"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').code()"><i class="fal fa-fw fa-code" v-b-tooltip.hover title="Insert code"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').link()"><i class="fal fa-fw fa-link" v-b-tooltip.hover title="Add a link"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').ul()"><i class="fal fa-fw fa-list-ul" v-b-tooltip.hover title="Add a bullet list"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').ol()"><i class="fal fa-fw fa-list-ol" v-b-tooltip.hover title="Add a numbered list"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').task()"><i class="fal fa-fw fa-tasks" v-b-tooltip.hover title="Add a task list"></i></button>
                      <button class="btn btn-link" type="button" @click="markdown.insert('edit-body').table()"><i class="fal fa-fw fa-table" v-b-tooltip.hover title="Add a table"></i></button>
                    </div>
                  </template>
                  <b-tab title="Write" active>
                    <Textarea ref="edit-body" v-model="edit.body" class="border-none text-monospace" placeholder="Write a comment" :autosize="false" />
                    <hr class="my-2">
                    <div class="row">
                      <div class="col-sm text-muted small py-1">
                        Styling with Markdown is supported
                      </div>
                      <div class="col-sm text-left text-sm-right">
                        <label class="btn btn-link py-1 px-0 m-0"><i class="fal fa-paperclip text-muted mr-1"></i>Attach a file<upload :group="project.group.path" :project="project.path" type="uploads" el="edit-body" @callback="insertLink" /></label>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Preview" class="preview markdown" @click="updatePreview('edit', edit.body)" v-html="preview.edit"></b-tab>
                </b-tabs>
              </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-primary btn-rounded mr-2" @click="updateComment()">Save Changes</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

          <b-modal id="deletecommentmodal" title="Delete Comment?" centered>
            This issue will be deleted the comment and any attachments.
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteComment()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

          <b-modal id="deleteissuemodal" title="Delete Issue?" centered>
            This issue will be deleted including all comments and attachments.
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteIssue()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ButtonGroupPlugin, ButtonPlugin, TabsPlugin, ModalPlugin, TooltipPlugin, DropdownPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import Textarea from '@/components/Textarea.vue';
import LabelBadge from '@/components/LabelBadge.vue';
import Upload from '@/components/Upload.vue';
import toast from '@/modules/toast';

Vue.use(ButtonGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(TabsPlugin);
Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(DropdownPlugin);

export default {
  data() {
    return {
      markdown: undefined,
      project: {},
      members: [],
      labels: [],
      boards: [],
      issue: [],
      edit: {
        id: '',
        title: '',
        body: '',
      },
      form: {
        body: '',
      },
      preview: {
        form: '',
        edit: '',
      },
      issueUpdateTimer: undefined,
      issueUpdateLast: Date.now(),
      editCommentTab: 0,
      postCommentTab: 0,
    };
  },
  computed: {
    participants() {
      const p = [];
      p.push(this.issue.description.created_by);
      for (let i = 0; i < this.issue.events.length; i += 1) {
        if (this.issue.events[i].event === 'opened' || this.issue.events[i].event === 'comment') {
          let inList = false;
          for (let k = 0; k < p.length; k += 1) {
            if (p[k].id === this.issue.events[i].created_by.id) {
              inList = true;
              break;
            }
          }
          if (!inList) {
            p.push(this.issue.events[i].created_by);
          }
        }
      }
      return p;
    },
    groupEvents() {
      const g = [this.issue.events[0]];
      for (let i = 1; i < this.issue.events.length; i += 1) {
        const e = this.issue.events[i];
        const last = g[g.length - 1];
        if (e.created_by.id === last.created_by.id && e.event === last.event && (e.event === 'label' || e.event === 'unlabel') && this.$datefns.diff(new Date(e.created_at), new Date(last.created_at), 'minutes') < 60) {
          last.created_at = e.created_at;
          if (last.ids) {
            last.ids.push(e.id);
          } else {
            last.ids = [last.id, e.id];
          }
          // check for duplicates
          for (let k = 0; k < last.ids.length - 1; k += 1) {
            if (last.ids[k] === e.id) {
              last.ids.splice(k, 1);
              break;
            }
          }
          if (e.event === 'label') {
            const eL = this.label(e.id);
            if (eL) {
              // remove old scoped label
              if (this.labelScope(eL)) {
                for (let k = 0; k < last.ids.length; k += 1) {
                  const lastL = this.label(last.ids[k]);
                  if (e.id !== last.ids[k] && lastL && this.labelScope(eL) === this.labelScope(lastL)) {
                    last.ids.splice(k, 1);
                    break;
                  }
                }
              }
            }
          }
        } else {
          g.push(JSON.parse(JSON.stringify(e)));
        }
      }
      return g;
    },
  },
  methods: {
    //
    // issue
    //
    updateIssue() {
      this.$api.get(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.$route.params.number}`)
        .then((res) => {
          // count number of items
          let issueCount = this.issue.description.comment.length;
          for (let i = 0; i < this.issue.comments.length; i += 1) {
            issueCount += this.issue.comments[i].comment.length;
          }
          for (let i = 0; i < this.issue.events.length; i += 1) {
            if (this.issue.events[i].event !== 'subscribe' && this.issue.events[i].event !== 'unsubscribe') {
              issueCount += 1;
            }
          }
          const issueLabels = [];
          for (let i = 0; i < this.issue.labels.length; i += 1) {
            issueLabels.push(this.issue.labels[i].id);
          }
          issueLabels.sort();
          // same for res
          let resCount = res.data.description.comment.length;
          for (let i = 0; i < res.data.comments.length; i += 1) {
            resCount += res.data.comments[i].comment.length;
          }
          for (let i = 0; i < res.data.events.length; i += 1) {
            if (res.data.events[i].event !== 'subscribe' && res.data.events[i].event !== 'unsubscribe') {
              resCount += 1;
            }
          }
          const resLabels = [];
          for (let i = 0; i < res.data.labels.length; i += 1) {
            resLabels.push(res.data.labels[i].id);
          }
          resLabels.sort();
          if (resCount !== issueCount || res.data.state !== this.issue.state || res.data.title !== this.issue.title || resLabels.join() !== issueLabels.join()) {
            // prevent new comments from being overwritten by concurrent update
            if (Date.now() - this.issueUpdateLast < 10000) {
              return;
            }
            this.issue = res.data;
            this.issueUpdateLast = Date.now();
          }
        })
        .catch(() => {});
    },
    async updateState(state) {
      try {
        await this.$api.patch(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}`, { state });
        this.issue.state = state;
        this.issue.events.push({ event: state === 'open' ? 'reopened' : 'closed', created_at: new Date(), created_by: this.$auth.profile });
      } catch (e) {
        toast.danger(this, e.response.data.message);
      }
    },
    async toggleAssignee(m) {
      const oldAssignedTo = this.issue.assigned_to;
      if (this.issue.assigned_to && this.issue.assigned_to.id === m.id) {
        this.issue.assigned_to = null;
      } else {
        // find member
        for (let i = 0; i < this.members.length; i += 1) {
          if (this.members[i].id === m.id) {
            this.issue.assigned_to = this.members[i];
            break;
          }
        }
      }
      try {
        await this.$api.patch(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}`, { assigned_to: this.issue.assigned_to ? this.issue.assigned_to.id : null });
        this.issue.events.push({ event: 'assignedto', created_at: new Date(), created_by: this.$auth.profile, id: this.issue.assigned_to ? this.issue.assigned_to.id : null });
      } catch (e) {
        this.issue.assigned_to = oldAssignedTo;
        toast.danger(this, e.response.data.message);
      }
    },
    deleteIssue() {
      this.$api.delete(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}`)
        .then(() => {
          this.$router.push(`/${this.project.group.path}/${this.project.path}/issues`);
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },

    //
    // notifications
    //
    subscribe(state) {
      let v = state;
      if (v === undefined) {
        v = false;
        for (let i = 0; i < this.issue.events.length; i += 1) {
          if (this.issue.events[i].event === 'subscribe' && this.issue.events[i].created_by.id === this.$auth.profile.id) {
            v = true;
          } else if (this.issue.events[i].event === 'unsubscribe' && this.issue.events[i].created_by.id === this.$auth.profile.id) {
            v = false;
          }
        }
      } else {
        this.$api.post(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}/${v ? 'subscribe' : 'unsubscribe'}`)
          .then(() => {
            this.issue.events.push({ event: v ? 'subscribe' : 'unsubscribe', created_at: new Date(), created_by: this.$auth.profile });
          })
          .catch((e) => {
            toast.danger(this, e.response.data.message);
          });
      }
      return v;
    },
    autoSubscribe() {
      for (let i = 0; i < this.issue.events.length; i += 1) {
        if ((this.issue.events[i].event === 'subscribe' || this.issue.events[i].event === 'unsubscribe') && this.issue.events[i].created_by.id === this.$auth.profile.id) {
          // don't subscribe user if they have modified notifications settings
          return;
        }
      }
      this.issue.events.push({ event: 'subscribe', created_at: new Date(), created_by: this.$auth.profile });
    },

    //
    // labels
    //
    toggleLabel(label) {
      if (this.labelActive(label)) {
        for (let i = 0; i < this.issue.labels.length; i += 1) {
          if (this.issue.labels[i].id === label.id) {
            this.issue.labels.splice(i, 1);
            break;
          }
        }
        this.$api.delete(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}/labels/${label.id}`)
          .then(() => {
            this.issue.events.push({ event: 'unlabel', created_at: new Date(), created_by: this.$auth.profile, id: label.id });
          })
          .catch((e) => {
            this.issue.labels.push(label);
            toast.danger(this, e.response.data.message);
          });
      } else {
        this.issue.labels.push(label);
        this.$api.put(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}/labels/${label.id}`)
          .then(() => {
            // remove old scoped label
            if (this.labelScope(label)) {
              for (let i = 0; i < this.issue.labels.length; i += 1) {
                if (this.issue.labels[i].id !== label.id && this.labelScope(this.issue.labels[i]) === this.labelScope(label)) {
                  this.issue.labels.splice(i, 1);
                  break;
                }
              }
            }
            this.issue.events.push({ event: 'label', created_at: new Date(), created_by: this.$auth.profile, id: label.id });
          })
          .catch((e) => {
            this.issue.labels.splice(this.issue.labels.length - 1, 1);
            toast.danger(this, e.response.data.message);
          });
      }
    },
    labelActive(label) {
      for (let i = 0; i < this.issue.labels.length; i += 1) {
        if (this.issue.labels[i].id === label.id) {
          return true;
        }
      }
      return false;
    },
    labelScope(label) {
      if (label.name.indexOf('::') !== -1) {
        return label.name.substring(0, label.name.indexOf('::'));
      }
      return undefined;
    },
    label(id) {
      for (let i = 0; i < this.labels.length; i += 1) {
        if (this.labels[i].id === id) {
          return this.labels[i];
        }
      }
      return undefined;
    },

    //
    // comment
    //
    comment(id) {
      for (let i = 0; i < this.issue.comments.length; i += 1) {
        if (this.issue.comments[i].id === id) {
          return this.issue.comments[i];
        }
      }
      return undefined;
    },
    updatePreview(type, body) {
      this.preview[type] = this.markdown.render(body);
    },
    editComment(modal, id) {
      this.preview.edit = '';
      if (this.issue.description.id === id) {
        this.edit.id = id;
        this.edit.title = this.issue.title;
        this.edit.body = this.issue.description.body;
      } else {
        for (let i = 0; i < this.issue.comments.length; i += 1) {
          if (this.issue.comments[i].id === id) {
            this.edit.id = id;
            this.edit.title = '';
            this.edit.body = this.issue.comments[i].body;
            break;
          }
        }
      }
      this.$bvModal.show(`${modal}commentmodal`);
    },
    updateComment() {
      let request;
      if (this.edit.id === this.issue.description.id) {
        this.edit.description = this.edit.body;
        request = this.$api.patch(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}`, this.edit)
          .then(() => {
            if (this.issue.title !== this.edit.title) {
              this.issue.events.push({ event: 'renamed', created_at: new Date(), created_by: this.$auth.profile, from: this.issue.title, to: this.edit.title });
            }
            this.issue.title = this.edit.title;
            this.issue.description.body = this.edit.body;
          });
      } else {
        request = this.$api.put(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}/comments/${this.edit.id}`, this.edit)
          .then(() => {
            for (let i = 0; i < this.issue.comments.length; i += 1) {
              if (this.issue.comments[i].id === this.edit.id) {
                this.issue.comments[i].body = this.edit.body;
                break;
              }
            }
          });
      }
      request
        .then(() => {
          this.$bvModal.hide('editcommentmodal');
          this.edit.id = '';
          this.edit.title = '';
          this.edit.body = '';
          this.preview.edit = '';
          delete this.edit.description;
          this.editCommentTab = 0;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    async postComment(state) {
      this.form.body = this.form.body.trim();
      if (this.form.body === '') {
        if (state && this.$auth.profile.site_admin) {
          this.updateState(state);
        }
        return;
      }
      if (state && state === 'open') {
        await this.updateState(state);
      }
      this.$api.post(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}/comments`, this.form)
        .then((res) => {
          this.issue.comments.push(res.data);
          this.issue.events.push({ event: 'comment', created_at: res.data.created_at, created_by: res.data.created_by, id: res.data.id });
          this.autoSubscribe();
          this.form.body = '';
          this.preview.form = '';
          if (state && state === 'closed') {
            this.updateState(state);
          }
          this.postCommentTab = 0;
          this.issueUpdateLast = Date.now();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteComment() {
      this.$api.delete(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.issue.number}/comments/${this.edit.id}`)
        .then(() => {
          this.$bvModal.hide('deletecommentmodal');
          for (let i = 0; i < this.issue.comments.length; i += 1) {
            if (this.issue.comments[i].id === this.edit.id) {
              this.issue.comments.splice(i, 1);
            }
          }
          for (let i = 0; i < this.issue.events.length; i += 1) {
            if (this.issue.events[i].id === this.edit.id) {
              this.issue.events.splice(i, 1);
            }
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },

    //
    // uploads
    //
    insertLink(res, el) {
      // insert file markdown
      let name = this.markdown.textarea(el).selection();
      if (name === '') {
        name = res.data.filename_alt;
      }
      if (res.data.mimetype.startsWith('image/')) {
        this.markdown.insert(el).image(name, res.data.url_alt);
      } else {
        this.markdown.insert(el).link(name, res.data.url_alt);
      }
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      this.members = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`)).data;
      this.labels = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels`)).data;
      this.boards = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/boards`)).data;
      this.issue = (await this.$api.get(`/projects/${this.project.group.path}%2F${this.project.path}/issues/${this.$route.params.number}`)).data;
      this.$route.meta.title = `${this.issue.title} - Issue #${this.issue.number}`;
      await import(/* webpackChunkName: "markdown" */ '@/modules/markdown').then(async (md) => {
        this.markdown = md.default;
        this.markdown.config(this, { linkify: true, mentions: true });
      });
      this.$store.commit('ready', true);
      this.issueUpdateTimer = setInterval(this.updateIssue, 10000);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  beforeDestroy() {
    clearInterval(this.issueUpdateTimer);
  },
  components: {
    Header,
    Textarea,
    LabelBadge,
    Upload,
  },
};
</script>

<style scoped>
.issuecontrols button i.fal { margin-top: -4px; }
</style>
